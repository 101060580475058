
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import { apiPath } from '../../constants/defaultValues';
// import axios from '../../helpers/Axios';
// import { auth } from '../../helpers/Firebase';
import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError
} from './actions';
import axios from '../../helpers/Axios';
import {post} from 'axios';
import {apiPath} from '../../constants/defaultValues';

const loginWithEmailPasswordAsync = async (email, password) =>
    post(`${apiPath}/authentication/cifss/token/`
    , { 
        username: email, 
        password: password,
    })
    .then(authUser => authUser)
    .catch(error => error);

function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        
        if (!loginUser.message) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + loginUser.data.key;
            localStorage.setItem('user_id', loginUser.data.user_id);
            localStorage.setItem('user_role', loginUser.data.role_name);
            localStorage.setItem('user_username', loginUser.data.username);
            localStorage.setItem('user_first_name', loginUser.data.first_name);
            localStorage.setItem('user_last_name', loginUser.data.last_name);
            localStorage.setItem('user_email', loginUser.data.email)
            localStorage.setItem('user_token', loginUser.data.key);
            localStorage.setItem('user_department', loginUser.data.department_id);

            yield put(loginUserSuccess(loginUser.data));
            history.push('/');
        } else {
            if(loginUser.message == "Request failed with status code 400"){
                const message = loginUser.response.data;
             
                if(message.email){
                    yield put(loginUserError(
                        message.message
                    ));
                    localStorage.setItem('validation', message.error_description)
                }
                else if(message.non_field_errors){
                    yield put(loginUserError(
                        "Failed to Login"
                    ));
                }
                else{
                    yield put(loginUserError(
                        "Failed to Login"
                    ));
                }
                
            }
            else{
                yield put(loginUserError(
                    "Failed to Login"
                ));
            }
        }
    } catch (error) {
        yield put(loginUserError(error));

    }
}

// const registerWithEmailPasswordAsync = async (email, password) =>
//     await auth.createUserWithEmailAndPassword(email, password)
//         .then(authUser => authUser)
//         .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push('/')
        } else {
            yield put(registerUserError(registerUser.message));

        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}



const logoutAsync = async (history) => {
    const token = localStorage.getItem('user_token');
    post(`${apiPath}/authentication/token/revoke/`
    , { 
       token: token
    })
    .then(authUser => authUser)
    .catch(error => error);
    history.push('/')
}

function* logout({ payload }) {
    const { history } = payload
    try {
        yield call(logoutAsync, history);
        localStorage.removeItem('user_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_role');
        localStorage.removeItem('user_username');
        localStorage.removeItem('user_first_name');
        localStorage.removeItem('user_last_name');
        localStorage.removeItem('user_email');
        localStorage.removeItem('user_department');
        localStorage.removeItem('validation')
    } catch (error) {
    }
}



export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        // fork(watchRegisterUser)
    ]);
}
/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

//API PATH
//PROD
// export const apiPath = "https://cifss-ost.sec.gov.ph";
//UAT
export const apiPath = "https://cifss.api.uat.comclark.tech";
//DEV
// export const apiPath = "https://cifss.api.dev.comclark.tech";
// export const apiPath = "http://10.30.140.12";
// export const apiPath = "http://121.58.254.199"


const token = localStorage.getItem('user_token');
export const apiHeader = { headers: { 'Authorization': `Token ${token}` }}
export const apiHeaderUpload = { headers: { 'content-type': 'multipart/form-data', 'Authorization': `Token ${token}` }}
export const defaultApiHeader = { headers: { 'Authorization': 'Bearer 8437effd6dc26c60c92919754649f89ec2b9cfb0' }}
/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false;
export const defaultColor = "light.green";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = false;
export const themeColorStorageKey="__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
import axios from 'axios';
export default axios.create({
  //PROD
  //  baseURL: 'https://cifss-ost.sec.gov.ph',
  //UAT
  baseURL: 'https://cifss.api.uat.comclark.tech',
  //DEV
  // baseURL: 'https://cifss.api.dev.comclark.tech',
  // baseURL: 'http://10.30.140.12',
  // baseURL: 'http://121.58.254.199',
  headers: {
    'Content-Type': 'application/json',
  }
});